//put aside until I have more time

// import { createSlice } from "@reduxjs/toolkit";

// export const jobSlice = createSlice({
//   name: "jobs",
//   initialState: [],
//   reducers: {
//     loadJobs: (state, { payload }) => {
//       // Redux Toolkit allows us to write "mutating" logic in reducers. It
//       // doesn't actually mutate the state because it uses the Immer library,
//       // which detects changes to a "draft state" and produces a brand new
//       // immutable state based off those changes
//       console.log(payload);
//       payload.map((job) => {
//         state.push(job);
//       });
//     },
//     addJob: (state, { payload }) => {
//       state.push(payload);
//     },

//     editJob: (state, { payload }) => {},

//     removeJob: () => {},
//   },
// });

// // Action creators are generated for each case reducer function
// export const { loadJobs, addJob, filter } = jobSlice.actions;

// export default jobSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import data from "../../DUMMY_DATA/jobdata.json";

export const jobSlice = createSlice({
  name: "jobs",
  initialState: data,
  reducers: {
    loadJobs: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = { ...action.payload };
    },
    addJob: (state, { payload }) => {
      state.push(payload);
    },

    editJob: (state, { payload }) => {},

    removeJob: () => {},
  },
});

// Action creators are generated for each case reducer function
export const { loadJobs, addJob, filter } = jobSlice.actions;

export default jobSlice.reducer;
