import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import {
  ApplicantSidebarData,
  AgencySidebarData,
  defaultSidebarData,
} from "./SidebarData";
import "./navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "Redux/User/userSlice";
import { logout } from "firebase.config";

const NavBar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState(defaultSidebarData);
  const navigate = useNavigate();
  const myUser = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    myUser.agency === "applicant"
      ? setSidebarData(ApplicantSidebarData)
      : myUser.agency !== "applicant" && myUser.loggedIn
      ? setSidebarData(AgencySidebarData)
      : setSidebarData(defaultSidebarData);
  }, [myUser]);

  return (
    <div>
      <div className="navbar">
        <Link to="#" className="menu-bars">
          <FaIcons.FaBars onClick={showSidebar} />
        </Link>
      </div>

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
              <AiIcons.AiOutlineClose />
            </Link>
          </li>
          {sidebarData.map((item, index) => {
            if (myUser.loggedIn && item.title === "Profile")
              myUser.agency === "applicant"
                ? (item.title = `${myUser.fName}'s Profile`)
                : (item.title = `${myUser.agency}'s Profile`);
            return item.path === "logout" ? (
              <li key={index} className={item.cName}>
                <button
                  onClick={() => {
                    dispatch(logOutUser());
                    logout();
                    navigate("/");
                  }}
                >
                  {item.icon}
                  <span>{item.title}</span>
                </button>
              </li>
            ) : (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
