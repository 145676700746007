import React, { useEffect, useId, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "Components/MultiSelect";
import keywords from "../../../DUMMY_DATA/keywords.json";
import { addJob } from "Redux/Jobs/jobSlice";
import { stateOptions } from "Components/stateOptions";
import "./addjob.css";
import { useNavigate } from "react-router-dom";
import { addJobPosting } from "firebase.config";

const AddJob = () => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [salary, setSalary] = useState("");
  const [summary, setSummary] = useState("");
  const [qualify, setQualify] = useState("");
  const [responsibility, setResponsibility] = useState("");
  const [description, setDescription] = useState("");
  const [agency, setAgency] = useState("");
  const [type, setType] = useState("");
  const [website, setWebsite] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [stateSel, setStateSel] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState("");
  const jobId = useId();
  const [job, setJob] = useState({});
  const [remote, setRemote] = useState(false);
  const dispatch = useDispatch();
  const jobList = useSelector((state) => state.jobs);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    console.log(jobList);
  }, [jobList]);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleTitle = (e) => {
    setTitle(e.target.value);
    setSubmitted(false);
  };

  const handleAgency = (e) => {
    setAgency(e.target.value);
    setSubmitted(false);
  };

  const handleSalary = (e) => {
    setSalary(e.target.value);
    setSubmitted(false);
  };

  const handleDate = (e) => {
    setDate(e.target.value);
    setSubmitted(false);
  };

  const handleStart = (e) => {
    setStartDate(e.target.value);
    setSubmitted(false);
  };

  const handleEnd = (e) => {
    setEndDate(e.target.value);
    setSubmitted(false);
  };

  const handleSummary = (e) => {
    setSummary(e.target.value);
    setSubmitted(false);
  };

  const handleQualify = (e) => {
    setQualify(e.target.value);
    setSubmitted(false);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setSubmitted(false);
  };

  const handleWebsite = (e) => {
    setWebsite(e.target.value);
    setSubmitted(false);
  };

  const handleResponsibility = (e) => {
    setResponsibility(e.target.value);
    setSubmitted(false);
  };

  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h1>New Job {title} successfully created!!</h1>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none",
        }}
      >
        <h1>Please enter all the fields</h1>
      </div>
    );
  };

  return (
    <div className="job-form-container">
      <div>
        <h1 className="new-job-h1">Create New Job Posting</h1>
      </div>

      <div className="messages">
        {errorMessage()}
        {successMessage()}
      </div>

      <form className="new-job-form">
        <label className="label">
          Title
          <input
            onChange={handleTitle}
            className="input"
            value={title}
            type="text"
            placeholder="Title name"
          />
        </label>
        <label className="label">
          Application Due Date
          <input
            onChange={handleDate}
            className="input"
            value={date}
            type="text"
          />
        </label>
        <label className="label">
          Summary
          <textarea
            onChange={handleSummary}
            className="text-input"
            value={summary}
            rows="3"
            cols="35"
            maxLength="600"
            placeholder="A short summary of the position"
          />
        </label>
        <label className="label">
          Locations
          <div className="signup-multiselect">
            <MultiSelect
              placeholder="Locations position is available"
              options={stateOptions}
              value={stateSel}
              onChange={(selectedOption) => {
                let state;
                if (selectedOption) {
                  state = selectedOption.map((selected) => ({
                    ...selected,
                  }));
                } else {
                  state = null;
                }
                setStateSel(state);
              }}
            />
          </div>
        </label>
        <label id="remote-job-input">
          <input
            type="checkbox"
            checked={remote}
            onChange={(e) => {
              setRemote(!remote);
            }}
          />
          Remote
        </label>
        <label className="label">
          Related Areas of Interest
          <div className="signup-multiselect">
            <MultiSelect
              placeholder="Select areas related to posting"
              options={keywords}
              value={selectedKeywords}
              onChange={(selectedOption) => {
                let state;
                if (selectedOption) {
                  state = selectedOption.map((selected) => ({
                    ...selected,
                  }));
                } else {
                  state = null;
                }
                setSelectedKeywords(state);
              }}
            />
          </div>
        </label>
        <label className="label">
          Employment Type
          <Select
            className="select-input"
            placeholder="Select type"
            options={[
              { value: "part", label: "Part Time" },
              { value: "full", label: "Full Time" },
              { value: "intern", label: "Internship" },
            ]}
            onChange={(e) => {
              setType(e.value);
            }}
          />
        </label>
        <label className="label">
          Agency
          <input
            onChange={handleAgency}
            className="input"
            disabled={!!user.agency}
            value={user.agency}
            type="text"
            placeholder="Hiring agency"
            cols={35}
          />
        </label>
        <label className="label">
          Salary
          <input
            onChange={handleSalary}
            className="input"
            value={salary}
            type="text"
            placeholder="Enter position salary"
          />
        </label>
        <label className="label">
          Position Start Date
          <input
            onChange={handleStart}
            className="input"
            value={startDate}
            type="date"
          />
        </label>
        <label className="label">
          Position End Date
          <input
            onChange={handleEnd}
            className="input"
            value={endDate}
            type="date"
          />
        </label>
        <label className="label">
          Position Description
          <textarea
            onChange={handleDescription}
            className="text-input"
            value={description}
            rows="8"
            cols="35"
            placeholder="Full position description"
          />
        </label>

        <label className="label">
          Responsibilities
          <textarea
            onChange={handleResponsibility}
            className="text-input"
            value={responsibility}
            cols="35"
            placeholder="Job Responsibilities"
          />
        </label>

        <label className="label">
          Qualifications
          <textarea
            onChange={handleQualify}
            className="text-input"
            value={qualify}
            cols="35"
            placeholder="Required Qualifications"
          />
        </label>

        <label className="label">
          Website
          <input
            onChange={handleWebsite}
            className="input"
            value={website}
            type="website"
            placeholder="Website for more info"
          />
        </label>
        <button
          onClick={(e) => {
            e.preventDefault();

            setJob({
              id: jobId,
              title: title,
              dueDate: date,
              summary: summary.split("\n").filter((str) => str !== ""),
              locations: stateSel,
              remote: remote,
              keywords: selectedKeywords,
              type: type,
              agency: user.agency,
              salary: salary,
              start: startDate,
              end: endDate,
              description: description.split("\n").filter((str) => str !== ""),
              responsibilities: responsibility
                .split("\n")
                .filter((str) => str !== ""),
              qualifications: qualify.split("\n").filter((str) => str !== ""),
              website: website,
            });
            addJobPosting(e, job);

            dispatch(addJob(job));
            navigate("/job-post-success");
          }}
          className="submit-btn job-submit"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddJob;
