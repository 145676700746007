import React, { useState, useId, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "Redux/User/userSlice";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
  db,
} from "../firebase.config";
import { query, getDocs, collection, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";
import Loading from "./Loading";

const Login = ({ loginType }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [myUser, setMyUser] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const testUser = useSelector((state) => state.user);

  const fetchUserName = async () => {
    console.log("fetched");
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));

      console.log(q);
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      data.loggedIn = true;
      dispatch(editUser(data));
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) {
      <Loading />;
      return;
    }
    if (testUser.loggedIn)
      testUser.agency === "applicant"
        ? navigate("/applicant-home")
        : testUser.agency !== "applicant" && testUser.agency !== "default"
        ? navigate("/agency-home")
        : navigate("/");
  }, [testUser]);

  const id = useId();

  return (
    <div className="form-container">
      <h2>{`For ${loginType === "agency" ? "Agencies" : "Applicants"}`}</h2>
      <div className="form-group">
        <span className="login-element">
          <label className="nowrap" htmlFor={`username-${id}`}>
            Username:
          </label>
          <input
            type="text"
            id={`username-${id}`}
            placeholder="Email Address"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </span>
        <span className="login-element">
          <label className="nowrap" htmlFor={`password-${id}`}>
            Password:
          </label>
          <input
            type="password"
            id={`password-${id}`}
            placeholder="Enter Password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </span>
      </div>

      <button
        className="login-btn"
        type="button"
        onClick={async (e) => {
          e.preventDefault();
          const isLoadCheck = await logInWithEmailAndPassword(email, password);
          if (isLoadCheck) fetchUserName();
        }}
      >
        Login
      </button>

      {loginType === "applicant" && (
        <button
          className="login-btn google"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            const isLoadCheck = await signInWithGoogle();
            if (isLoadCheck) fetchUserName();
          }}
        >
          Login with Google
        </button>
      )}
      <p>
        <Link to="/reset">Forgot Password</Link>
      </p>

      <p>
        New to MINRC Job Portal?{" "}
        <Link to={`new-${loginType}`}>Create a profile.</Link>
      </p>
    </div>
  );
};

export default Login;
