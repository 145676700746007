import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ApplicantProfile() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  console.log(user);
  return user.loggedIn ? (
    <div>
      <h1>{user.fName}'s Profile</h1>
      <div className="profile-content">
        <h4>
          Name: {user.fName} {user.lName}
        </h4>
        <h4>Email: {user.email}</h4>
        <h4>Location: {user.stateSel}</h4>
        <h4>MINRC Cohort Year {user.minrcYear}</h4>
        <h4>Completed Level of Shool: {user.education}</h4>
        <h4> Interests:</h4>
        <ul>
          {user.interests.map((interests, index) => {
            return <li key={index}>{interests.label}</li>;
          })}
        </ul>
        <h4> Skills:</h4>
        <ul>
          {user.skills.map((skills, index) => {
            return <li key={index}>{skills.label}</li>;
          })}
        </ul>
        <h4>Profesional Summary</h4>
        {user.summary.map((summary, index) => {
          return <p key={index}>{summary}</p>;
        })}
        <button className="edit-btn">Edit</button>
      </div>
    </div>
  ) : (
    <>
      <h2>You must log in to view this page</h2>
      {navigate("/")}
    </>
  );
}

export default ApplicantProfile;
