import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MultiSelect } from "Components/MultiSelect";
import skilloptions from "../../DUMMY_DATA/skilloptions.json";
import keywords from "../../DUMMY_DATA/keywords.json";
import { stateOptions } from "Components/stateOptions";
import "./applicantsignup.css";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "firebase.config";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const ApplicantSignup = () => {
  const navigate = useNavigate();

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [minrcYear, setMinrcYear] = useState("");
  const [education, setEducation] = useState("");
  const [skills, setSkills] = useState("");
  const [stateSel, setStateSel] = useState("");
  const [interests, setInterests] = useState("");
  const [summary, setSummary] = useState("");

  const [firebaseErrorMsg, setFirebaseErrorMsg] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [submitted, setSubmitted] = useState(false);
  const authType = "applicant";

  const register = (e) => {
    e.preventDefault();
    registerWithEmailAndPassword(
      fName,
      email,
      password,
      lName,
      minrcYear,
      education,
      skills,
      stateSel,
      interests,
      summary.split("\n").filter((str) => str !== ""),
      authType
    );
    setSubmitted(true);
    setTimeout(() => navigate("/"), 3000);
  };

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (user) {
      console.log(user);
    }
  }, [user, loading]);

  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h1
          style={{
            color: "green",
            textAlign: "center",
          }}
        >
          User {fName} successfully registered!!
        </h1>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: alert ? "" : "none",
        }}
      >
        <h4
          style={{
            color: "red",
            textAlign: "center",
          }}
        >
          {firebaseErrorMsg
            ? firebaseErrorMsg
            : "Please fill out all fields in the form"}
        </h4>
      </div>
    );
  };

  return (
    <div className="applicant-form-container">
      <div>
        <h1 className="signup-h1">Applicant Registration</h1>
      </div>

      <div className="messages">
        {errorMessage()}
        {successMessage()}
      </div>

      <form onSubmit={register} className="new-applicant-form">
        <label className="label">
          First Name
          <input
            onChange={(e) => setFName(e.target.value)}
            className="input"
            value={fName}
            type="text"
            placeholder="First name"
          />
        </label>
        <label className="label">
          Last Name
          <input
            onChange={(e) => setLName(e.target.value)}
            className="input"
            value={lName}
            type="text"
            placeholder="Last name"
          />
        </label>

        <label className="label">
          Email
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="input"
            value={email}
            type="email"
            placeholder="Best email address"
          />
        </label>

        <label className="label">
          Password
          <input
            onChange={(e) => setPassword(e.target.value)}
            className="input"
            value={password}
            type="password"
            placeholder="Password"
          />
        </label>

        <label className="label">
          MINRC Cohort Year
          <input
            onChange={(e) => setMinrcYear(e.target.value)}
            type="number"
            min="2007"
            max="2026"
            step="1"
            placeholder="YYYY"
            value={minrcYear}
          />
        </label>

        <label className="label">
          Current Location
          <Select
            className="select-input"
            placeholder="Select a state"
            options={stateOptions}
            onChange={(e) => {
              setStateSel(e.value);
            }}
          />
        </label>

        <label className="label">
          Highest Level of Education
          <Select
            className="select-input"
            placeholder="Select education"
            options={[
              { value: "highschool", label: "Highschool" },
              { value: "undergraduate", label: "Undergraduate" },
              { value: "graduate", label: "Graduate School" },
            ]}
            onChange={(e) => {
              setEducation(e.value);
            }}
          />
        </label>

        <label className="label">
          Skills
          <div className="signup-multiselect">
            <MultiSelect
              placeholder="Select up to 5 skills"
              max={5}
              options={skilloptions}
              value={skills}
              onChange={(selectedOption) => {
                let state;
                if (selectedOption) {
                  state = selectedOption.map((selected) => ({
                    ...selected,
                  }));
                } else {
                  state = null;
                }
                setSkills(state);
              }}
            />
          </div>
        </label>

        <label className="label">
          Areas of Interest
          <div className="signup-multiselect">
            <MultiSelect
              placeholder="Select up to 5 areas of interest"
              max={5}
              options={keywords}
              value={interests}
              onChange={(selectedOption) => {
                let state;
                if (selectedOption) {
                  state = selectedOption.map((selected) => ({
                    ...selected,
                  }));
                } else {
                  state = null;
                }
                setInterests(state);
              }}
            />
          </div>
        </label>

        <label className="label">
          Professional Summary
          <textarea
            onChange={(e) => setSummary(e.target.value)}
            className="text-input"
            value={summary}
            rows="6"
            cols="40"
            maxLength="1000"
            placeholder="A 3-5 sentence professional summary"
          />
        </label>

        <button className="submit-btn" type="submit">
          Register
        </button>

        <button
          onClick={(e) => {
            e.preventDefault();
            signInWithGoogle(
              fName,
              lName,
              minrcYear,
              education,
              skills,
              stateSel,
              interests,
              summary.split("\n").filter((str) => str !== ""),
              authType
            );
          }}
          className="submit-btn google"
          type="button"
        >
          Register with Google
        </button>

        <div className="login-text">
          Already have an account? <Link to="/">Login</Link> now.
        </div>
      </form>
    </div>
  );
};

export default ApplicantSignup;
